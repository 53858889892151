import { useDebounce } from '@uidotdev/usehooks';
import { InputHTMLAttributes, ReactNode, useEffect, useState } from 'react';
import {
  Controller,
  FieldErrors,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import InputMask, { Props } from 'react-input-mask';

import { classMerge } from '@/common/utils';

import { Spinner } from '../Spinner';

import * as style from './styles';

function getErrorMessage(errors: FieldErrors<FieldValues>, fieldName: string) {
  const splittedName = fieldName.split('.');
  let currentLevel = errors;

  // eslint-disable-next-line no-restricted-syntax
  for (const propertyName of splittedName) {
    if (currentLevel && currentLevel[propertyName]) {
      currentLevel = currentLevel[propertyName] as FieldErrors<FieldValues>;
    }
  }

  return currentLevel.message as unknown as string;
}

type TextInputProps = {
  size?: style.TextInputVariants['size'];
  name: string;
  label?: string;
  labelClassName?: string;
  LeadingIcon?: ReactNode;
  leadingIconClassName?: string;
  TrailingIcon?: ReactNode;
  handleClickTrailingIcon?: () => void;
  mask?: Props['mask'];
  isTelephone?: boolean;
  isDirty?: boolean;
  isErrored?: boolean;
  errorMessage?: string;
  isLoading?: boolean;
  debounce?: {
    fn: (value: string) => void;
    ms: number;
  };
  noPaste?: boolean;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>;

export function TextInput({
  size = 'md',
  name,
  label,
  labelClassName,
  LeadingIcon,
  TrailingIcon,
  handleClickTrailingIcon,
  mask,
  isTelephone,
  isDirty,
  isErrored,
  errorMessage,
  debounce,
  isLoading,
  noPaste,
  ...props
}: TextInputProps) {
  const { register, formState, watch, control, getValues } = useFormContext();
  const [telephoneMask, setTelephoneMask] = useState(
    getValues(name)?.length === 10 ? '(99)  9999-9999' : '(99) 9 9999-9999',
  );

  const { errors, dirtyFields } = formState;

  const hasError = isErrored || !!getErrorMessage(errors, name);

  const isSuccessful = Boolean(
    !hasError && (isDirty || dirtyFields[name]) && watch(name),
  );
  const debouncedValue = useDebounce(watch(name), debounce?.ms || 0);

  useEffect(() => {
    if (debouncedValue) {
      debounce?.fn(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <div className="w-full text-start">
      <div className="relative">
        {LeadingIcon && (
          <div
            className={style.ContainerIcon({
              leading: true,
              isLabelled: !!label,
            })}
          >
            {LeadingIcon}
          </div>
        )}

        {label && (
          <label
            htmlFor={name}
            className={classMerge(style.Label(), labelClassName)}
          >
            {label}
          </label>
        )}

        {isTelephone && (
          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field }) => {
              return (
                <InputMask
                  id={name}
                  mask={telephoneMask}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={event => {
                    if (event.target.value.length === 15) {
                      setTelephoneMask('(99) 9999-9999');
                    }
                  }}
                  onFocus={() => setTelephoneMask('(99) 9 9999-9999')}
                  className={style.Input({
                    hasError,
                    isSuccessful:
                      isSuccessful && !isLoading && !formState.isSubmitting,
                    hasLeadingIcon: !!LeadingIcon,
                    hasTrailingIcon: !!TrailingIcon,
                    size,
                    isDisabled:
                      props.disabled || isLoading || formState.isSubmitting,
                  })}
                  maskChar={null}
                  disabled={
                    props.disabled || isLoading || formState.isSubmitting
                  }
                  onDrop={noPaste ? e => e.preventDefault() : undefined}
                  onPaste={noPaste ? e => e.preventDefault() : undefined}
                  {...props}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                    }
                  }}
                />
              );
            }}
          />
        )}

        {mask && (
          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <InputMask
                id={name}
                mask={mask}
                value={field.value}
                onChange={field.onChange}
                className={style.Input({
                  hasError,
                  isSuccessful:
                    isSuccessful && !isLoading && !formState.isSubmitting,
                  hasLeadingIcon: !!LeadingIcon,
                  hasTrailingIcon: !!TrailingIcon,
                  size,
                  isDisabled:
                    props.disabled || isLoading || formState.isSubmitting,
                })}
                maskChar={null}
                disabled={props.disabled || isLoading || formState.isSubmitting}
                onDrop={noPaste ? e => e.preventDefault() : undefined}
                onPaste={noPaste ? e => e.preventDefault() : undefined}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                  }
                }}
                {...props}
              />
            )}
          />
        )}

        {!mask && !isTelephone && (
          <input
            id={name}
            className={style.Input({
              hasError: !!errorMessage || isErrored || hasError,
              isSuccessful:
                isSuccessful && !isLoading && !formState.isSubmitting,
              hasLeadingIcon: !!LeadingIcon,
              hasTrailingIcon: !!TrailingIcon,
              size,
              isDisabled: props.disabled || isLoading || formState.isSubmitting,
            })}
            disabled={props.disabled || isLoading || formState.isSubmitting}
            {...register(name)}
            onDrop={noPaste ? e => e.preventDefault() : undefined}
            onPaste={noPaste ? e => e.preventDefault() : undefined}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
            {...props}
          />
        )}

        {TrailingIcon && (
          <div
            className={style.ContainerIcon({
              leading: false,
              isLabelled: !!label,
            })}
            onClick={handleClickTrailingIcon}
            onKeyDown={handleClickTrailingIcon}
            role="button"
            tabIndex={0}
          >
            {TrailingIcon}
          </div>
        )}

        {isLoading && (
          <div
            className={style.ContainerIcon({
              leading: false,
              isLabelled: !!label,
            })}
          >
            <Spinner />
          </div>
        )}

        {isLoading && (
          <div
            className={style.ContainerIcon({
              leading: false,
              isLabelled: !!label,
              isDisabled: props.disabled || isLoading || formState.isSubmitting,
            })}
          >
            <Spinner />
          </div>
        )}
      </div>

      {(hasError || !!errorMessage) && (
        <p className={style.ErrorMessage()}>
          {getErrorMessage(errors, name) || errorMessage}
        </p>
      )}
    </div>
  );
}
