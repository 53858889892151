import { cva } from 'class-variance-authority';

export const icon = cva(['h-4', 'w-4', 'rounded-full', 'mx-2', 'object-cover']);

export const dropButton = cva([
  'flex',
  'items-center',
  'gap-2',
  'px-1',
  'py-1',
  'shadow-sm',
  'border',
  'border-neutral-300',
  'rounded-md',
  'cursor-pointer',
  'hover:bg-gray-100/80',
  'min-w-fit',
]);

export const text = cva(['text-neutral-600', 'text-sm', 'hidden', 'sm:flex']);
