import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';
import { RootLayout } from '@/layouts/Root';

const Pricer = lazy(() => import('@/modules/pricer/pages/Pricer'));

export const PricerRoutes: RouteObject[] = [
  {
    element: <RootLayout />,
    children: [
      {
        path: links.pricer,
        element: <Pricer />,
      },
    ],
  },
];
