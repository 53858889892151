import { InputHTMLAttributes, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

import * as style from './styles';

type CheckboxProps = {
  name: string;
  label?: string;
  extraClassNames?: string;
  children?: ReactNode;
  enableShowError?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export function Checkbox({
  name,
  label,
  extraClassNames,
  children,
  enableShowError = true,
  ...props
}: CheckboxProps) {
  const { register, formState } = useFormContext();
  const { errors } = formState;

  return (
    <>
      <div className={style.container()}>
        <input
          type="checkbox"
          className={style.checkbox()}
          id={name}
          {...register(name)}
          {...props}
        />
        <label htmlFor={name} className={`${style.label()} ${extraClassNames}`}>
          {children}
          {label}
        </label>
      </div>

      {enableShowError && errors[name]?.message && (
        <p className={style.errorMessage()}>
          {errors[name]?.message as string}
        </p>
      )}
    </>
  );
}
