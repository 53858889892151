import * as style from './styles';

export default function SidebarSkeleton() {
  return (
    <div className={style.sidebar()}>
      <div className={style.wrapperSections()}>
        <div>
          <div className={style.wrapperLogo()} />
          <div className={style.buttonSkeleton()} />
          <div className={style.buttonSkeleton()} />
        </div>

        <div>
          <div className={style.horizontalDivider()} />

          <div className={style.avatarWrapper()}>
            <div className={style.avatar()} />
            <div className={style.textWrapper()}>
              <div className={style.name()} />
              <div className={style.role()} />
            </div>
          </div>

          <div className={style.buttonSkeleton()} />
          <div className={style.buttonSkeleton()} />
          <div className={style.buttonSkeleton()} />
        </div>
      </div>
    </div>
  );
}
