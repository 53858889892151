import { cva } from 'class-variance-authority';

export const SubTrigger = cva([
  'flex',
  'cursor-default',
  'select-none',
  'items-center',
  'rounded-sm',
  'px-2',
  'py-1.5',
  'text-sm',
  'outline-none',
  'focus:bg-neutral-100',
  'data-[state=open]:bg-neutral-100',
]);

export const SubContent = cva([
  'z-50',
  'min-w-[8rem]',
  'overflow-hidden',
  'rounded-md',
  'border',
  'border-neutral-200',
  'bg-white',
  'p-1',
  'text-neutral-950',
  'shadow-lg',
  'data-[state=open]:animate-in',
  'data-[state=closed]:animate-out',
  'data-[state=closed]:fade-out-0',
  'data-[state=open]:fade-in-0',
  'data-[state=closed]:zoom-out-95',
  'data-[state=open]:zoom-in-95',
  'data-[side=bottom]:slide-in-from-top-2',
  'data-[side=left]:slide-in-from-right-2',
  'data-[side=right]:slide-in-from-left-2',
  'data-[side=top]:slide-in-from-bottom-2',
]);

export const MenuContent = cva([
  'z-50',
  'min-w-[8rem]',
  'overflow-hidden',
  'rounded-md',
  'border',
  'border-neutral-200',
  'bg-white',
  'p-1',
  'text-neutral-950',
  'shadow-md',
  'data-[state=open]:animate-in',
  'data-[state=closed]:animate-out',
  'data-[state=closed]:fade-out-0',
  'data-[state=open]:fade-in-0',
  'data-[state=closed]:zoom-out-95',
  'data-[state=open]:zoom-in-95',
  'data-[side=bottom]:slide-in-from-top-2',
  'data-[side=left]:slide-in-from-right-2',
  'data-[side=right]:slide-in-from-left-2',
  'data-[side=top]:slide-in-from-bottom-2',
]);

export const MenuItem = cva([
  'relative',
  'flex',
  'cursor-default',
  'select-none',
  'items-center',
  'rounded-sm',
  'px-2',
  'py-1.5',
  'text-sm',
  'outline-none',
  'transition-colors',
  'focus:bg-neutral-100',
  'focus:text-neutral-900',
  'data-[disabled]:pointer-events-none',
  'data-[disabled]:opacity-50',
]);

export const MenuCheckboxItem = cva([
  'relative',
  'flex',
  'cursor-default',
  'select-none',
  'items-center',
  'rounded-sm',
  'py-1.5',
  'pl-8',
  'pr-2',
  'text-sm',
  'outline-none',
  'transition-colors',
  'focus:bg-neutral-100',
  'focus:text-neutral-900',
  'data-[disabled]:pointer-events-none',
  'data-[disabled]:opacity-50',
]);

export const MenuRadioItem = cva([
  'relative',
  'flex',
  'cursor-default',
  'select-none',
  'items-center',
  'rounded-sm',
  'py-1.5',
  'pl-8',
  'pr-2',
  'text-sm',
  'text-neutral-800',
  'outline-none',
  'transition-colors',
  'focus:bg-neutral-100',
  'data-[disabled]:pointer-events-none',
  'data-[disabled]:opacity-50',
]);

export const MenuLabel = cva(['px-2', 'py-1.5', 'text-sm', 'font-semibold']);

export const MenuSeparator = cva(['-mx-1', 'my-1', 'h-px', 'bg-neutral-100']);

export const MenuShortcut = cva([
  'ml-auto',
  'text-xs',
  'tracking-widest',
  'opacity-60',
]);

export const Container = cva([
  'absolute',
  'left-2',
  'flex',
  'h-3.5',
  'w-3.5',
  'items-center',
  'justify-center',
]);
