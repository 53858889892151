import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';
import { RootLayout } from '@/layouts/Root';

const Contracts = lazy(() => import('@/modules/contract/pages/Contracts'));

export const ContractsRoutes: RouteObject[] = [
  {
    element: <RootLayout />,
    children: [
      {
        path: links.contract,
        element: <Contracts />,
      },
    ],
  },
];
