import { cva } from 'class-variance-authority';

export const Loading = cva(
  [
    'animate-spin',
    'inline-block',
    'border-[3px]',
    'border-current',
    'border-t-transparent',
    'text-blue-600',
    'rounded-full',
  ],
  {
    variants: {
      size: {
        sm: ['w-4', 'h-4'],
        md: ['w-6', 'h-6'],
        lg: ['w-8', 'h-8'],
        xl: ['w-12', 'h-12'],
      },
      variant: {
        brand: ['text-blue-600'],
        neutral: ['text-neutral-600'],
        danger: ['text-driver-centricity-600'],
        white: ['text-white'],
      },
    },
  },
);
