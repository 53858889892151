import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';
import { RootLayout } from '@/layouts/Root';

const Reports = lazy(() => import('@/modules/reports/pages/Reports'));

export const ReportsRoutes: RouteObject[] = [
  {
    element: <RootLayout />,
    children: [
      {
        path: links.reports,
        element: <Reports />,
      },
    ],
  },
];
