import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';
import { RootLayout } from '@/layouts/Root';

const Clients = lazy(() => import('@/modules/clients/pages/Clients'));

export const ClientsRoutes: RouteObject[] = [
  {
    element: <RootLayout />,
    children: [
      {
        path: links.clients,
        element: <Clients />,
      },
    ],
  },
];
