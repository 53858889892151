import { IStaticMethods } from 'preline/preline';
import { Suspense, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { LoadingFallback } from '@/common/components/LoadingFallback';

import { Sidebar } from './components/Sidebar';
import { StoreSelectionModal } from './components/StoreSelectionModal';
import { container, content } from './styles';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

export function RootLayout() {
  const location = useLocation();

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  return (
    <div className={container()}>
      <Sidebar />

      <div className={content()}>
        <Suspense fallback={<LoadingFallback />}>
          <Outlet />
        </Suspense>
      </div>

      <StoreSelectionModal />
    </div>
  );
}
