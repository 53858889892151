import { cva } from 'class-variance-authority';

export const Container = cva(
  [
    'inline-block',
    'bg-neutral-200',
    'rounded-full',
    'overflow-hidden',
    'shadow-sm',
  ],
  {
    variants: {
      size: {
        small: ['h-6', 'w-6'],
        medium: ['h-11', 'w-12'],
        large: ['h-16', 'w-16'],
      },
    },
  },
);

export const AvatarIcon = cva(
  ['inline-block', 'rounded-full', 'ring-2', 'ring-white'],
  {
    variants: {
      size: {
        small: ['h-6', 'w-6'],
        medium: ['h-11', 'w-12'],
        large: ['h-16', 'w-16'],
      },
    },
  },
);

export const DefaultIcon = cva(
  [
    'h-full',
    'w-full',
    'bg-neutral-100',
    'flex',
    'items-center',
    'justify-center',
    'font-inter',
    'font-semibold',
    'text-brand-900',
    'select-none',
  ],
  {
    variants: {
      size: {
        small: ['text-sm'],
        medium: ['text-xl'],
        large: ['text-2xl'],
      },
    },
  },
);
