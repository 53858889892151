import { cva } from 'class-variance-authority';

export const Icon = cva(['fill-white', 'font-bold']);

export const Container = cva(
  ['flex', 'border', 'rounded-md', 'p-4', 'w-[416px]', 'relative', 'shadow-sm'],
  {
    variants: {
      type: {
        success: ['bg-sustainability-green-900'],
        error: ['bg-driver-centricity-800'],
        warning: ['bg-alert-500'],
        info: ['bg-brand-pure'],
        default: ['bg-brand-pure'],
      },
    },
  },
);

export const Content = cva(['flex', 'items-center']);

export const IconContainer = cva(['mr-5', 'mt-1', 'self-start']);

export const Body = cva([]);

export const Message = cva(['text-lg', 'text-white', 'font-semibold']);

export const Description = cva(['text-base', 'text-white', 'font-light']);

export const CloseIcon = cva([
  'text-white',
  'absolute',
  'top-3',
  'right-3',
  'cursor-pointer',
]);
