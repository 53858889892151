export function returnAvatarInitials(fullName: string): string {
  const names = fullName.split(' ');

  if (names.length === 1) {
    return names[0].slice(0, 2).toUpperCase();
  }
  if (names.length > 1) {
    const firstName = names[0];
    const lastName = names[names.length - 1];
    return (firstName[0] + lastName[0]).toUpperCase();
  }
  return '';
}
