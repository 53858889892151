import { X } from 'lucide-react';
import { TypeOptions } from 'react-toastify';

import AlertTriangleFill from '@/assets/icons/alert-triangle-fill.svg?react';
import CheckCircleFill from '@/assets/icons/check-circle-fill.svg?react';
import Info from '@/assets/icons/info.svg?react';

import * as style from './styles';

type ToastProps = {
  message: string;
  description?: string | null;
  type: TypeOptions;
  handleClose?: () => void;
};

const ICON_BY_TYPE: Record<TypeOptions, JSX.Element> = {
  success: <CheckCircleFill className={style.Icon()} />,
  error: <AlertTriangleFill className={style.Icon()} />,
  warning: <AlertTriangleFill className={style.Icon()} />,
  info: <Info className={style.Icon()} />,
  default: <Info className={style.Icon()} />,
};

export function Toast({ message, description, type, handleClose }: ToastProps) {
  return (
    <div className={style.Container({ type })}>
      <div className={style.Content()}>
        <div className={style.IconContainer()}>
          {ICON_BY_TYPE[type] || ICON_BY_TYPE.default}
        </div>

        <div className={style.Body()}>
          <p className={style.Message()}>{message}</p>
          {description && <p className={style.Description()}>{description}</p>}
        </div>
      </div>

      <button type="button" onClick={handleClose}>
        <X className={style.CloseIcon()} size={16} />
      </button>
    </div>
  );
}
