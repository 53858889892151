import { lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import { links } from '@/common/utils/links';
import { LoginRoutes } from '@/modules/login';

const PrivacyPolicy = lazy(() => import('@/common/components/PrivacyPolicy'));

export const PublicRoutes = createBrowserRouter([
  ...LoginRoutes,
  {
    path: links.privacyPolicy,
    element: <PrivacyPolicy />,
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
]);
