import { IAppNavigation } from '@/@types/AppNavigation';
import { useFlagsmith } from 'flagsmith/react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { Tooltip } from '@/common/components/Tooltip';

import { useCollapseSidebar } from '../..';

import * as style from './styles';

type NavItemProps = {
  data: IAppNavigation;
};

export function NavItem({ data }: NavItemProps) {
  const { hasFeature } = useFlagsmith();
  const location = useLocation();
  const { dealId } = useParams();
  const { collapsed, openSidebar } = useCollapseSidebar();

  const { icon: Icon, activeIcon: ActiveIcon } = data;

  const { titleIcon: TitleIcon } = data;

  const hasChildren = data?.children?.length;
  const pathname = location.pathname.replace(`/${dealId}`, '');
  const isActive =
    data?.activePaths?.includes(pathname) || pathname === data.link;

  const isExternal = data?.external;

  if (data.flag && !hasFeature(data.flag)) return null;

  return (
    <div className={style.menuItem()} key={data.key}>
      {!hasChildren && (
        <Link
          className={style.link({
            active: isActive,
          })}
          to={data.link!}
          target={isExternal ? '_blank' : '_self'}
        >
          {Icon && (
            <>
              {ActiveIcon && isActive ? (
                <ActiveIcon
                  className={style.icon({
                    isActive,
                  })}
                  size={18}
                />
              ) : (
                <Icon
                  className={style.icon({
                    isActive,
                  })}
                  size={18}
                />
              )}
            </>
          )}
          {TitleIcon ? (
            <span className={style.itemTextTitle()}>
              {data.title}
              <span className={style.profileChevronRight()}>
                {TitleIcon && <TitleIcon size={12} />}
              </span>
            </span>
          ) : (
            <span className={style.itemText()}>{data.title}</span>
          )}
        </Link>
      )}

      {hasChildren && (
        <Tooltip message={data.title}>
          <button
            type="button"
            className={style.accordionToggle()}
            onClick={openSidebar}
          >
            <div className={style.accordionTitle()}>
              {Icon && (
                <Icon
                  className={style.icon({
                    isActive,
                  })}
                  size={18}
                />
              )}
              <span className={style.itemText()}>{data.title}</span>
            </div>

            <ChevronUp className={style.arrowUp()} size={12} />

            <ChevronDown className={style.arrowDown()} size={12} />
          </button>
        </Tooltip>
      )}

      <div className={style.accordionContent()}>
        <ul className="pr-2">
          {collapsed
            ? null
            : data?.children?.map(menuChildren => {
                if (menuChildren.flag && !hasFeature(menuChildren.flag))
                  return null;

                return (
                  <li key={menuChildren.key} className={style.accordionItem()}>
                    <div className={style.verticalDivider()} />

                    <Link
                      className={style.link({
                        active: location.pathname === menuChildren.link,
                      })}
                      to={menuChildren.link!}
                    >
                      {menuChildren.title}
                    </Link>
                  </li>
                );
              })}
        </ul>
      </div>
    </div>
  );
}
