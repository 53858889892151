import { returnAvatarInitials } from '@/common/utils/returnAvatarInitials';

import * as style from './styles';

type AvatarSize = 'small' | 'medium' | 'large';

interface IAvatar {
  url?: string | null;
  size?: AvatarSize;
  name: string;
}

export default function Avatar({ url, size = 'medium', name }: IAvatar) {
  return (
    <span className={style.Container({ size })}>
      {url ? (
        <img className={style.AvatarIcon({ size })} src={url} alt="Avatar" />
      ) : (
        <div className={style.DefaultIcon({ size })}>
          {returnAvatarInitials(name)}
        </div>
      )}
    </span>
  );
}
