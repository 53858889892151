import { cva } from 'class-variance-authority';

export const container = cva(['flex', 'w-full', 'h-full', 'flex-col']);

export const content = cva([
  'flex',
  'w-full',
  'h-[calc(100vh-10rem)]',
  'sm:h-[calc(100vh-12rem)]',
  'flex',
  'gap-4',
]);
