import { cva } from 'class-variance-authority';

export const container = cva(
  [
    'hs-overlay',
    'hidden',
    'w-full',
    'h-full',
    'fixed',
    'top-0',
    'start-0',
    'z-[60]',
    'overflow-x-hidden',
    'overflow-y-auto',
  ],
  {
    variants: {
      dispensable: {
        false: ['[--overlay-backdrop:static]'],
      },
    },
  },
);

export const modal = cva([
  'hs-overlay-open:mt-7',
  'hs-overlay-open:opacity-100',
  'hs-overlay-open:duration-500',
  'mt-0',
  'opacity-0',
  'ease-out',
  'transition-all',
  'sm:max-w-5xl',
  'lg:w-full',
  'm-3',
  'lg:mx-auto',
  'h-[calc(100%-3.5rem)]',
  'min-h-[calc(100%-3.5rem)]',
  'flex',
  'items-center',
]);

export const wrapper = cva([
  'w-full',
  'max-h-full',
  'overflow-hidden',
  'flex',
  'flex-col',
  'bg-white',
  'border',
  'shadow-sm',
  'rounded-xl',
  'py-4',
  'px-8',
  'md:py-10',
  'md:px-20',
]);

export const header = cva(['flex', 'justify-between', 'items-start', 'w-full']);

export const headerTitle = cva([
  'flex',
  'flex-col',
  'justify-between',
  'items-start',
  'w-full',
  'pb-8',
]);

export const title = cva([
  'font-semibold',
  'text-brand-800',
  'text-1xl',
  'sm:text-2xl',
  'md:text-3xl',
  'flex',
  'items-center',
  'gap-4',
]);

export const subTitle = cva([
  'font-normal',
  'text-neutral-700',
  'text-xs',
  'sm:text-base',
  'md:text-lg',
  'mt-2',
]);

export const closeButton = cva([
  'flex',
  'justify-center',
  'items-center',
  'w-7',
  'h-7',
  'text-sm',
  'font-semibold',
  'rounded-full',
  'border',
  'border-transparent',
  'text-gray-800',
  'hover:bg-gray-100',
  'disabled:opacity-50',
  'disabled:pointer-events-none',
]);

export const content = cva([
  'py-8',
  'pt-4',
  'pb-8',
  'overflow-y-auto',
  'border-t',
]);

export const footer = cva(['border-t', 'w-full']);
