import { cva } from 'class-variance-authority';

export const container = cva([
  'flex',
  'items-center',
  'justify-center',
  'h-screen',
  'w-screen',
  'py-[10vh]',
  'px-[5vw]',
  'gap-10',
]);

export const banner = cva([
  'w-full',
  'h-full',
  'hidden',
  'sm:flex',
  'sm:items-center',
  'sm:justify-center',
  'rounded-2xl',
]);

export const bannerImage = cva([
  'h-full',
  'w-full',
  'object-cover',
  'rounded-2xl',
]);
