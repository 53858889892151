import { useFlagsmith } from 'flagsmith/react';
import { Link, useLocation } from 'react-router-dom';

import { useUserProfileMenus } from '@/common/hooks/useUserProfileMenus';

import * as style from './styles';

export function Sidebar() {
  const menus = useUserProfileMenus();
  const location = useLocation();
  const { hasFeature } = useFlagsmith();

  return (
    <nav className={style.container()}>
      <ul className={style.wrapperLinks()}>
        {menus?.map(menu => {
          if (menu.flag && !hasFeature(menu.flag)) return null;
          if (!menu?.link) return null;

          return (
            <li key={menu.key} className={style.navItem()}>
              <Link
                to={menu.link}
                className={style.link({
                  active: location.pathname.includes(menu.link),
                })}
              >
                <span
                  className={style.text({
                    active: location.pathname.includes(menu.link),
                  })}
                >
                  {menu.title}
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
