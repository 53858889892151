import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';

import SidebarSkeleton from '@/common/components/SidebarSkeleton';
import { useAppRouter } from '@/common/hooks/useAppRouter';

export function AppRoutes() {
  const { activeRouter } = useAppRouter();

  return (
    <Suspense fallback={<SidebarSkeleton />}>
      <RouterProvider router={activeRouter} />
    </Suspense>
  );
}
