import { cva } from 'class-variance-authority';

export const main = cva([
  'sm:min-w-[384px]',
  'flex',
  'flex-col',
  'gap-2',
  'justify-between',
  'font-inter',
  'h-full',
  'overflow-y-auto',
  'p-1',
]);

export const title = cva([
  'text-5xl',
  'font-semibold',
  'text-neutral-900',
  'mb-3',
]);

export const subTitle = cva([
  'text-xl',
  'sm:text-2xl',
  'font-light',
  'text-neutral-700',
]);

export const header = cva(['flex', 'flex-col', 'gap-4', 'w-full']);

export const form = cva(['w-full', 'flex', 'flex-col', 'gap-6']);

export const options = cva([
  'flex',
  'items-start',
  'gap-4',
  'sm:gap-0',
  'sm:items-center',
  'justify-around',
  'flex-col',
  'sm:flex-row',
]);

export const forgotPassword = cva(['text-sm', 'text-brand-pure']);

export const reCaptchaContainer = cva(['w-full', 'flex', 'flex-col', 'gap-2']);

export const reCaptcha = cva(
  [
    'w-full',
    'flex',
    'justify-between',
    'items-center',
    'border',
    'rounded-lg',
    'border-neutral-200',
    'p-3',
  ],
  {
    variants: {
      hasError: {
        true: ['border-red-500'],
      },
    },
  },
);

export const reCaptchaError = cva(['text-sm', 'text-red-500']);

export const privacyPolicy = cva(['text-sm', 'text-center', 'text-brand-pure']);

export const copyright = cva(['text-xs', 'text-neutral-400', 'text-center']);

export const icon = cva(['text-neutral-500', 'w-4', 'h-4']);

export const button = cva(['w-full', 'h-16', 'mt-auto']);

export const divider = cva([
  'w-[2px]',
  'h-6',
  'bg-neutral-200',
  'rounded',
  'hidden',
  'sm:block',
]);

export const lock = cva(['text-brand-pure', 'mr-2']);

export const wrapperLock = cva(['flex', 'justify-around', 'items-center']);

export const checkbox = cva(['!m-0', '!ml-2']);

export const footer = cva([
  'flex',
  'flex-col',
  'items-center',
  'gap-3',
  'justify-end',
]);

export const warnText = cva([
  'font-inter',
  'font-semibold',
  'text-base',
  'text-alert-800',
]);
