import { IPerson } from './Person';
import { IUserPreference } from './UserPreference';

export enum UserTypeEnum {
  ACCOUNT = 'ACCOUNT',
  DEALER = 'DEALER',
}

export enum UserRoleEnum {
  ROOT = 'ROOT',
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  SUPPORT = 'SUPPORT',
  OPERATOR = 'OPERATOR',
  SELLER = 'SELLER',
}

export enum UserStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED',
}

export interface IUser {
  id: string;
  personId: string;
  email: string;
  displayName: string;
  userType: UserTypeEnum;
  status: UserStatusEnum;
  lastPasswordChangeAt: null;
  isUserFirstLogin: true;
  changePasswordOnNextLogin: false;
  termOfUseAcceptedDate: '2024-01-18T21:04:24.602Z';
  isActive: true;
}

export type IUpdateUser = Partial<IUser>;

export interface IUserAccount {
  userId: string;
  role: UserRoleEnum;
  status: UserStatusEnum;
}

export interface IValidateDealerUser {
  userId: string;
  personId: string;
  email: string;
  displayName: string;
  userType: UserTypeEnum;
  activeRole: UserRoleEnum;
  person: IPerson;
  userPreference: IUserPreference;
  isActive: boolean;
}

export interface ICreateUser {
  email: string;
  displayName: string;
  userType: UserTypeEnum;
  status: UserStatusEnum;
  storeId?: string | null;
  person: IPerson;
}

export interface IUserOwner {
  personId: string;
  email: string;
  displayName: string;
  userType: UserTypeEnum;
  status: UserStatusEnum;
  Person: IPerson;
}
