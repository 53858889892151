import { IAxiosError } from '@/@types/AxiosError';
import { zodResolver } from '@hookform/resolvers/zod';
import { ClipboardSignature, FileDown } from 'lucide-react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import Button from '@/common/components/Button';
import { Checkbox } from '@/common/components/Checkbox';
import { Modal } from '@/common/components/Modal';
import { useToast } from '@/common/hooks/useToast';
import { ENV } from '@/config/env';
import { useAcceptTerms } from '@/modules/login/api/useAcceptTerms';
import { useLogin } from '@/modules/login/hooks/useLogin';
import { SignInForm } from '@/modules/login/schemas/SignInSchema';

import * as styles from './styles';

type AcceptTermsOfUseForm = {
  termsOfUse: boolean;
};

type AcceptTermsOfUseProps = {
  recoverFormData: () => SignInForm;
};

export function AcceptTermsOfUse({ recoverFormData }: AcceptTermsOfUseProps) {
  const { t } = useTranslation(['common', 'login/signIn']);
  const { toast } = useToast();
  const { signOut } = useLogin();

  const hookForm = useForm<AcceptTermsOfUseForm>({
    resolver: zodResolver(
      z.object({
        termsOfUse: z.boolean().refine(value => value, {
          message: t('login/signIn:acceptTermsOfUse'),
        }),
      }),
    ),
  });

  const { signIn } = useLogin();
  const { acceptTerms, isLoadingAcceptTerms } = useAcceptTerms();

  const onSubmit = async () => {
    try {
      const data = recoverFormData();
      await signIn(data);
      await acceptTerms();
      window.HSOverlay.close(document.getElementById('hs-modal-terms-of-use')!);
      toast.success(t('login/signIn:successAcceptTermsOfUse'));
    } catch (error) {
      toast.error(JSON.stringify(error));
      const requestError = error as IAxiosError;
      toast.error(
        requestError?.response?.data?.friendlyMessage ||
          t('login/signIn:errorAcceptTermsOfUse'),
      );
    } finally {
      signOut();
    }
  };

  return (
    <FormProvider {...hookForm}>
      <Modal
        id="modal-terms-of-use"
        title={t('login/signIn:termsOfUse')}
        subTitle={t('login/signIn:termsOfUseSubTitle')}
        titleIcon={<ClipboardSignature size={24} className={styles.icon()} />}
        dispensable={false}
        footer={
          <div className={styles.container()}>
            <Checkbox name="termsOfUse">
              <label htmlFor="termsOfUse" className={styles.label()}>
                <span>{t('accept')}</span>

                <span className={styles.textHighlight()}>
                  {t('login/signIn:terms')}
                </span>

                <span>{t('and')}</span>

                <span className={styles.textHighlight()}>
                  {t('login/signIn:privacy')}
                </span>
              </label>
            </Checkbox>

            <div className={styles.actions()}>
              <Button
                type="button"
                variant="secondary"
                leadingIcon={<FileDown className="text-brand-pure" />}
                text={t('login/signIn:downloadTerms')}
                className={styles.buttonDownload()}
                onClick={() => window.open(ENV.VITE_TERMS_OF_USE_URL, '_bank')}
              />

              <Button
                type="button"
                text={t('continue')}
                className={styles.buttonSubmit()}
                onClick={hookForm.handleSubmit(onSubmit)}
                isLoading={isLoadingAcceptTerms}
              />
            </div>
          </div>
        }
      />
    </FormProvider>
  );
}
