import { z } from 'zod';

export const SignInSchema = z.object({
  username: z.string().nonempty(),
  password: z.string().nonempty(),
  reCaptcha: z.boolean().refine(value => value === true),
  rememberData: z.boolean(),
  reCaptchaToken: z.string().optional().nullable(),
});

export type SignInForm = z.infer<typeof SignInSchema>;
