import { ReactNode } from 'react';

import { Breadcrumb, IBreadcrumb } from './components/Breadcrumb';
import * as style from './styles';

interface IHeader {
  title: string;
  breadcrumbs?: IBreadcrumb[];
  children?: ReactNode;
}

export default function Header({ title, breadcrumbs, children }: IHeader) {
  return (
    <header className={style.container()}>
      <div className={style.heading()}>
        <h1 className={style.title()}>{title}</h1>

        <div className={style.breadcrumbContainer()}>
          {breadcrumbs?.map((breadcrumb, index) => (
            <Breadcrumb
              key={breadcrumb?.title}
              isLink={
                Boolean(breadcrumb?.link) && index < breadcrumbs?.length - 1
              }
              isActive={index !== 0 && index === breadcrumbs?.length - 1}
              isLast={index === breadcrumbs?.length - 1}
              data={breadcrumb}
            />
          ))}
        </div>
      </div>
      <div className={style.actionsWrapper()}>{children}</div>
    </header>
  );
}
