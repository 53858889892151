import { cva } from 'class-variance-authority';

export const container = cva([
  'min-w-[248px]',
  'flex',
  'min-h-full',
  'mb-2',
  'overflow-hidden',
]);

export const wrapperLinks = cva([
  'w-full',
  'md:text-start',
  'text-center',
  'flex',
  'flex-col',
  'gap-3',
]);

export const navItem = cva([
  'w-full',
  'flex',
  'max-h-[40px]',
  'items-center',
  'justify-center',
]);

export const link = cva(
  ['w-full', 'py-3', 'px-4', 'rounded-md', 'hover:bg-brand-50'],
  {
    variants: {
      active: {
        true: ['bg-brand-50'],
      },
    },
  },
);

export const text = cva(
  ['font-inter', 'text-xs', 'md:text-sm', 'font-semibold', 'transition-colors'],  {
    variants: {
      active: {
        true: ['text-brand-800'],
        false: ['text-neutral-800'],
      },
    },
    defaultVariants: {
      active: false,
    },
  },
);
