import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';
import { RootLayout } from '@/layouts/Root';
import { UserProfileLayout } from '@/layouts/UserProfile';

const UserProfileOptions = lazy(() => import('../pages/userProfileOptions'));
const Profile = lazy(() => import('../pages/profile'));
const Password = lazy(() => import('../pages/password'));

export const UserProfileRoutes: RouteObject[] = [
  {
    element: <RootLayout />,
    children: [
      {
        element: <UserProfileLayout />,
        children: [
          {
            path: links.userProfile.profile,
            element: <Profile />,
          },
          {
            path: links.userProfile.password,
            element: <Password />,
          },
          {
            path: links.userProfile.userProfileOptions,
            element: <UserProfileOptions />,
          },
        ],
      },
    ],
  },
];
