import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';
import { RootLayout } from '@/layouts/Root';

const Dealers = lazy(() => import('@/modules/dealers/pages/Dealers'));

export const DealersRoutes: RouteObject[] = [
  {
    element: <RootLayout />,
    children: [
      {
        path: links.dealers,
        element: <Dealers />,
      },
    ],
  },
];
