import flagsmith from 'flagsmith';
import Cookies from 'js-cookie';
import { LayoutGrid } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@/common/components/Modal';
import { StoreCard } from '@/common/components/StoreCard';
import { SaveUserStoreProps, useUserStore } from '@/common/hooks/UserStore';
import { useToast } from '@/common/hooks/useToast';

import * as style from './styles';

export function StoreSelectionModal() {
  const { t } = useTranslation(['login/storeSelection', 'common']);
  const { saveUserStore, userStores, storeId: currentStoreId } = useUserStore();
  const { toast } = useToast();

  const handleLoadDealerStore = async (selectedStore: SaveUserStoreProps) => {
    const { dealerId, storeId, role } = selectedStore;

    Cookies.set('dealerId', dealerId);
    Cookies.set('storeId', storeId);
    Cookies.set('role', role);
    await flagsmith.setTrait('role', role);
    await flagsmith.setTrait('dealerId', dealerId);

    saveUserStore({
      dealerId,
      storeId,
      role,
    });
    window.HSOverlay.close(document.getElementById('hs-system-access-modal')!);
    toast.success(t('common:success'), t('storeChanged'));
  };

  return (
    <Modal
      id="system-access-modal"
      title={t('changeStoreAccess')}
      titleIcon={<LayoutGrid size={24} className={style.icon()} />}
      dispensable
      subTitle={
        <p className={style.subTitle()}>
          {t('selectStore')} <strong className={style.nexpro()}>QTOOL</strong>.
        </p>
      }
    >
      <div className={style.content()}>
        <section className={style.section()}>
          <ul className={style.list()}>
            {userStores?.map(store => (
              <li key={store.id}>
                <StoreCard
                  store={store}
                  currentStoreId={currentStoreId!}
                  onClick={() =>
                    handleLoadDealerStore({
                      dealerId: store.dealerId,
                      storeId: store.id,
                      role: store.role,
                    })
                  }
                />
              </li>
            ))}
          </ul>
        </section>
      </div>
    </Modal>
  );
}
