import { create } from 'zustand';

interface ILoginStatusStore {
  loginStatus: boolean;
  setLoginStatus: (status: boolean) => void;
}

export const useLoginStatusStore = create<ILoginStatusStore>(set => {
  return {
    loginStatus: false as boolean,
    setLoginStatus(status: boolean) {
      set({ loginStatus: status });
    },
  };
});
