import { ENV } from '@/config/env';
export const links = {
  privacyPolicy: `/privacy-policy`,
  termsOfUse: ENV.VITE_TERMS_OF_USE_URL,
  login: {
    main: `/`,
    storeSelection: `/store-selection`,
    forgotPassword: `/forgot-password`,
    resetPassword: `/reset-password`,
    activateUser: `/activate-user`,
    acceptTerms: `/accept-terms`,
  },
  quotes: {
    list: `/`,
    new: `/quotes/new`,
    steps: `/quotes/new/steps`,
    newProduct: `/quotes/newProduct`,
    newContract: `/quotes/contract/new`,
    contractSteps: `/quotes/contract/new/steps`,
    cart: (dealId = ':dealId') => `/quotes/cart/${dealId}`,
  },
  contract: `/contract`,
  userProfile: {
    profile: `/user-profile/profile`,
    password: `/user-profile/password`,
    userProfileOptions: `/user-profile/`,
  },
  pricer: `/pricer`,
  clients: `/clients`,
  dealer: `/dealer`,
  dealers: `/dealers`,
  store: `/store`,
  stores: `/stores`,
  reports: `/reports`,
  settings: {
    users: `/settings/users`,
    planProductsParts: `/settings/plan-products-parts`,
    contractTemplate: `/settings/contract-template`,
    dealer: `/settings/dealer`,
    store: `/settings/store`,
    products: `/settings/products`,
    models: `/settings/models`,
    materials: `/settings/materials`,
    plans: `/settings/plans`,
    globalConfig: `/settings/global-settings`,
    chassis: `/settings/chassis`,
    settingsOptions: `/settings/`,
    priceTable: `/settings/price-table`,
    campaigns: `/settings/campaigns`,
  },
};
