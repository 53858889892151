import QToolLogo from '@/assets/icons/qtool.svg';

import * as style from './styles';

export function LoadingFallback() {
  return (
    <div className={style.container()}>
      <img className={style.image()} src={QToolLogo} alt="..." />
    </div>
  );
}
