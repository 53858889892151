import { X } from 'lucide-react';
import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import * as style from './styles';

type ModalProps = {
  id: string;
  title: string;
  titleIcon?: ReactElement;
  subTitle?: string | ReactNode;
  children?: ReactElement[] | ReactElement;
  dispensable?: boolean;
  onClose?: () => void;
  footer?: ReactNode;
};

export function Modal({
  id,
  title,
  titleIcon,
  subTitle,
  children,
  dispensable,
  onClose,
  footer,
}: ModalProps) {
  const { t } = useTranslation('common');

  return (
    <div
      id={`hs-${id}`}
      className={style.container({ dispensable })}
      data-hs-overlay-keyboard={dispensable}
    >
      <div className={style.modal()}>
        <div className={style.wrapper()}>
          {/* HEADER */}
          <div className={style.header()}>
            <div className={style.headerTitle()}>
              <h3 className={style.title()}>
                {title}
                {titleIcon}
              </h3>
              <h4 className={style.subTitle()}>{subTitle}</h4>
            </div>

            {dispensable && (
              <button
                type="button"
                className={style.closeButton()}
                data-hs-overlay={`#hs-${id}`}
                onClick={onClose}
              >
                <span className="sr-only">{t('close')}</span>
                <X size={18} />
              </button>
            )}
          </div>
          {/* HEADER */}

          {children && <div className={style.content()}>{children}</div>}

          {footer && <div className={style.footer()}>{footer}</div>}
        </div>
      </div>
    </div>
  );
}
