import { cva } from 'class-variance-authority';

export const container = cva(
  [
    'rounded-lg',
    'border',
    'border-secondary-1',
    'hover:border-brand-pure',
    'hover:shadow-md',
    'cursor-pointer',
    'relative',
    'w-full',
    'h-full',
    'flex',
    'p-4',
  ],
  {
    variants: {
      selected: {
        true: ['border-orange-700', 'shadow-md', 'bg-orange-100'],
        false: ['border-neutral-200', 'shadow-sm'],
      },
    },
  },
);

export const logo = cva([
  'w-16',
  'h-16',
  'object-center',
  'rounded-full',
  'border',
  'border-secondary-2',
  'self-center',
]);

export const info = cva(['px-4', 'text-left', 'w-full']);

export const badge = cva([
  'hidden',
  'h-full',
  'w-full',
  'lg:grid',
  'items-center',
  'justify-end',
]);

export const title = cva([
  'text-neutral-800',
  'text-base',
  'font-normal',
  'max-w-full',
  'overflow-hidden overflow-ellipsis whitespace-pre',
  'pb-1',
]);

export const description = cva(['text-neutral-700', 'text-xs', 'font-light']);

export const icon = cva(['text-brand-pure']);
