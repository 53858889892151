import { cva } from 'class-variance-authority';

export const container = cva([
  'flex',
  'flex-col',
  'items-center',
  'w-full',
  'pt-8',
]);

export const icon = cva(['text-brand-pure']);

export const label = cva(['flex', 'items-center', 'gap-1', 'text-neutral-700']);

export const textHighlight = cva(['text-brand-pure', 'font-semibold']);

export const actions = cva(['flex', 'gap-4', 'mt-8', 'w-full']);

export const buttonDownload = cva(['text-brand-pure', 'flex-1']);

export const buttonSubmit = cva(['flex-1']);
