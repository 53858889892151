import { cva } from 'class-variance-authority';

export const subTitle = cva([
  'text-neutral-700',
  'text-md',
  'sm:text-lg',
  'font-normal',
]);

export const nexpro = cva([
  'text-transparent',
  'bg-clip-text bg-brand-gradient',
]);

export const icon = cva(['text-brand-pure']);

export const content = cva(['space-y-8']);

export const section = cva(['flex', 'flex-col', 'gap-6', 'w-full', 'mx-auto']);

export const sectionInfo = cva(['space-y-2', 'self-start']);

export const sectionTitle = cva([
  'text-neutral-900',
  'text-1xl',
  'sm:text-2xl',
  'font-semibold',
]);

export const sectionSubTitle = cva([
  'text-neutral-700',
  'text-sm',
  'sm:text-lg',
  'font-light',
]);

export const list = cva([
  'flex',
  'flex-col',
  'sm:grid',
  'sm:grid-cols-2',
  'gap-6',
]);

export const tipContainer = cva([
  'flex',
  'items-center',
  'justify-center',
  'gap-2',
  'sm:gap-4',
  'w-full',
]);

export const tip = cva([
  'text-neutral-600',
  'text-sm',
  'sm:text-base',
  'font-light',
]);

export const actions = cva(['flex', 'justify-center', 'w-full', 'mt-auto']);

export const button = cva(['w-full', 'max-w-sm', 'h-16']);
