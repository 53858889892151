import { cva } from 'class-variance-authority';

export const breadcrumbWrapper = cva(['flex', 'gap-5', 'items-center']);

export const breadcrumb = cva(['text-lg'], {
  variants: {
    active: {
      true: ['font-semibold', 'text-brand-800'],
      false: ['font-regular', 'text-neutral-700'],
    },
    isLink: {
      true: ['hover:font-semibold'],
    },
  },
  defaultVariants: {
    active: false,
    isLink: false,
  },
});
