import {} from '@/@types/Dealer';

import { maskCNPJ } from '@/common/utils';

import { Badge } from '../Badge';

import * as style from './styles';

type StoreCardProps = {
  store: any;
  currentStoreId?: string;
  onClick?: () => void;
};

export function StoreCard({ store, currentStoreId, onClick }: StoreCardProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={style.container({
        selected: store.id === currentStoreId,
      })}
    >
      {store.Dealer?.logoUrl && (
        <img
          src={store.Dealer?.logoUrl}
          alt={store.Dealer.displayName}
          className={style.logo()}
        />
      )}

      <div className={style.info()}>
        <p className={style.title()}>{store.displayName}</p>

        <p className={style.description()}>{maskCNPJ(store.documentNumber)}</p>
        <p className={style.description()}>
          {`${store.addressMetadata.street}, ${store.addressMetadata?.streetNumber}`}
          <br />
          {`${store.addressMetadata.city} - ${store.addressMetadata.state}`}
        </p>
      </div>

      <div className={style.badge()}>
        <Badge title={store.Dealer.displayName.toUpperCase()} />
      </div>
    </button>
  );
}
