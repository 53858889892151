import { IAppNavigation } from '@/@types/AppNavigation';
import { VariantProps } from 'class-variance-authority';
import { useFlagsmith } from 'flagsmith/react';
import {
  Users2,
  Store,
  FileText,
  ChevronLeft,
  ChevronRight,
  Menu,
  Receipt,
  ClipboardEdit,
} from 'lucide-react';
import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { create } from 'zustand';

import DealerWhiteLogo from '@/assets/icons/concessionarias-white.svg';
import DealerLogo from '@/assets/icons/dealers.svg?react';
import SystemTinyLogoImg from '@/assets/icons/logo.svg';
import QToolLogo from '@/assets/icons/qtool.svg';
import { links } from '@/common/utils/links';

import { Config } from './components/Config';
import { NavItem } from './components/NavItem';
import * as style from './styles';

interface ISidebarStore {
  collapsed: boolean;
  toggleSidebar: () => void;
  openSidebar: () => void;
  closeSidebar: () => void;
}

export const useCollapseSidebar = create<ISidebarStore>(set => ({
  collapsed: false,
  toggleSidebar: () => set(state => ({ collapsed: !state.collapsed })),
  openSidebar: () => set({ collapsed: false }),
  closeSidebar: () => set({ collapsed: true }),
}));

type ContainerProps = VariantProps<typeof style.container>;

export function Sidebar() {
  const [containerBottom, setContainerBottom] =
    useState<ContainerProps['containerBottom']>('bottom-auto');
  const [hideMenu, setHideMenu] = useState(true);
  const { hasFeature } = useFlagsmith();
  const { collapsed, toggleSidebar } = useCollapseSidebar();
  const { t } = useTranslation('sidebar/menus');

  const handleToggle = () => {
    toggleSidebar();
  };

  const handleToggleContainerStyle = () => {
    setContainerBottom(state =>
      state === 'bottom-auto' ? 'bottom-0' : 'bottom-auto',
    );
  };

  useLayoutEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [hideMenu]);

  const menus: IAppNavigation[] = [
    {
      key: 'quotes',
      title: t('sidebar/menus:quotes'),
      link: links.quotes.list,
      activePaths: [
        links.quotes.list,
        links.quotes.new,
        links.quotes.steps,
        links.quotes.newProduct,
        links.quotes.newContract,
        links.quotes.contractSteps,
        links.quotes.cart().replace('/:dealId', ''),
      ],
      flag: 'sidebar_quotes_menu',
      icon: Receipt,
    },
    {
      key: 'contract',
      title: t('sidebar/menus:contract'),
      link: links.contract,
      flag: 'sidebar_minutes_menu',
      icon: ClipboardEdit,
    },
    // TODO: REATIVAR ESTE MENU QUANDO FOR INSERIR O PRECIFICADOR
    // {
    //   key: 'pricer',
    //   title: t('sidebar/menus:pricer'),
    //   link: links.pricer,
    //   flag: 'sidebar_pricer_menu',
    //   icon: Calculator,
    // },
    {
      key: 'clients',
      title: t('sidebar/menus:clients'),
      icon: Users2,
      link: links.clients,
      flag: 'sidebar_clients_menu',
    },
    {
      key: 'dealers',
      title: t('sidebar/menus:dealers'),
      icon: () => <DealerLogo className="w-[18px] h-[18px]" />,
      activeIcon: () => (
        <img src={DealerWhiteLogo} alt={t('sidebar/menus:dealers')} />
      ),
      link: links.dealers,
      flag: 'sidebar_dealer_menu',
    },
    {
      key: 'store',
      title: t('sidebar/menus:stores'),
      link: links.stores,
      icon: Store,
      flag: 'sidebar_store_menu',
    },
    {
      key: 'reports',
      title: t('sidebar/menus:reports'),
      icon: FileText,
      link: links.reports,
      flag: 'sidebar_reports_menu',
    },
  ];

  return (
    <div
      className={style.container({ containerBottom })}
      data-collapsed={collapsed}
    >
      <button
        type="button"
        className={style.collapseButton()}
        onClick={handleToggle}
      >
        <ChevronLeft size={14} className="group-data-[collapsed=true]:hidden" />
        <ChevronRight
          size={14}
          className="hidden group-data-[collapsed=true]:block"
        />
      </button>

      <section className={style.header()}>
        <img
          src={QToolLogo}
          alt="Iveco"
          className={`${style.logo()} group-data-[collapsed=true]:hidden`}
        />
        <img
          src={SystemTinyLogoImg}
          alt="Iveco"
          className={`${style.logo()} hidden group-data-[collapsed=true]:block`}
        />

        <button
          type="button"
          className="bg-brand-pure p-1 text-white rounded-sm md:hidden hs-collapse-toggle"
          data-hs-collapse="#hs-basic-collapse-heading"
          onClick={() => {
            handleToggleContainerStyle();
            setHideMenu(state => !state);
          }}
        >
          <Menu size={20} />
        </button>
      </section>

      <div
        id="hs-basic-collapse-heading"
        onClick={() => {
          setHideMenu(true);
          handleToggleContainerStyle();
        }}
        className={`${style.content()} ${hideMenu ? 'hidden' : ''}`}
      >
        <nav className={style.menu()}>
          {menus.map(menu => {
            if (menu.flag && !hasFeature(menu.flag)) return null;
            return (
              <button
                key={menu.key}
                type="button"
                data-hs-collapse="#hs-basic-collapse-heading"
              >
                <NavItem data={menu} />
              </button>
            );
          })}
        </nav>
        <Config />
      </div>
    </div>
  );
}
