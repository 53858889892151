import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';

import Header from '@/common/components/Header';
import { useUserStore } from '@/common/hooks/UserStore';
import { links } from '@/common/utils/links';

import { Sidebar } from './components/Sidebar';
import * as style from './styles';

export function UserProfileLayout() {
  const { t } = useTranslation(['common', 'userProfile', 'enums/role']);
  const { pathname } = useLocation();
  const { user, person } = useUserStore();
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const ROUTE_COMPONENT = {
    [links.userProfile.profile]: (
      <>
        <Header
          title={t('userProfile/profile:settingsProfile')}
          breadcrumbs={[
            { title: person?.socialName || user?.displayName },
            {
              title: t('userProfile/profile:myProfile'),
            },
          ]}
        />

        <div className="flex flex-row gap-1">
          {isWideScreen && <Sidebar />}
          <div className={style.content()}>
            <Outlet />
          </div>
        </div>
      </>
    ),
    [links.userProfile.password]: (
      <>
        <Header
          title={t('userProfile/password:settingsProfile')}
          breadcrumbs={[
            { title: person?.socialName || user?.displayName },
            {
              title: t('userProfile/password:password'),
            },
          ]}
        />

        <div className="flex flex-row gap-1">
          {isWideScreen && <Sidebar />}
          <div className={style.content()}>
            <Outlet />
          </div>
        </div>
      </>
    ),
    [links.userProfile.userProfileOptions]: (
      <>
        <div className={style.content()}>
          <Outlet />
        </div>
      </>
    ),
  };

  return (
    <div className={style.container()}>
      <Helmet>
        <title>{`${t('common:userProfile')} - QTool by IVECO`}</title>
      </Helmet>

      {ROUTE_COMPONENT[pathname]}
    </div>
  );
}
