import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { Outlet } from 'react-router-dom';

import {
  NewProposalForm,
  NewProposalSchema,
} from '../../schemas/NewQuoteSchema';

export function NewQuoteForm() {
  const newQuoteHookForm = useForm<NewProposalForm>({
    resolver: zodResolver(NewProposalSchema),
    defaultValues: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dealerId: null,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      storeId: null,
      title: '',
      tags: [],
    },
  });

  return (
    <FormProvider {...newQuoteHookForm}>
      <Outlet />
    </FormProvider>
  );
}
