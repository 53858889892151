import { httpClient } from '@/config/httpClient';
import { GetTokenCredentials } from '@/modules/login/hooks/useLogin';

export interface ISignInResponse {
  id_token: string;
  scope: string;
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_expires_in: number;
  session_state: string;
}

export function signInService(payload: GetTokenCredentials) {
  return httpClient.post<ISignInResponse>('/oauth/token', payload, {});
}
