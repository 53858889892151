import { useTranslation } from 'react-i18next';

import * as style from './styles';

type SpinnerProps = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  variant?: 'brand' | 'neutral' | 'danger' | 'white';
};

export function Spinner({ size = 'sm', variant = 'brand' }: SpinnerProps) {
  const { t } = useTranslation('common');

  return (
    <div
      className={style.Loading({ size, variant })}
      role="status"
      aria-label="loading"
    >
      <span className="sr-only">{t('loading')}</span>
    </div>
  );
}
