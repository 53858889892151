import { useMutation } from '@tanstack/react-query';

import { acceptTerms } from '../services/acceptTerms';

export function useAcceptTerms() {
  const { mutateAsync: mutateAcceptTerms, isLoading: isLoadingAcceptTerms } =
    useMutation(acceptTerms);

  return {
    acceptTerms: mutateAcceptTerms,
    isLoadingAcceptTerms,
  };
}
