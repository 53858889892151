import { cva } from 'class-variance-authority';

export const menuItem = cva([
  'hs-accordion',
  'cursor-pointer',
  'group-data-[collapsed=true]:pr-0',
]);

export const profileChevronRight = cva(['pl-1', 'text-brand-pure']);

export const accordionToggle = cva([
  'hs-accordion-toggle',
  'flex',
  'items-center',
  'justify-between',
  'gap-x-3.5',
  'p-3.5',
  'rounded-md',
  'w-full',
  'group-data-[collapsed=true]:w-12',
  'text-sm',
  'font-inter',
  'truncate',
  'text-neutral-700',
  'hover:bg-brand-50',
  'hover:text-brand-400',
]);

export const accordionTitle = cva(['flex', 'items-center', 'gap-4']);

export const arrowUp = cva([
  'hidden',
  'hs-accordion-active:block',
  'text-brand-pure',
  'group-data-[collapsed=true]:sr-only',
]);

export const arrowDown = cva([
  'hs-accordion-active:hidden',
  'text-brand-pure',
  'group-data-[collapsed=true]:sr-only',
]);

export const accordionContent = cva([
  'hs-accordion-content',
  'overflow-hidden',
  'duration-300',
  'hidden',
  'overflow-y-auto',
  'max-h-[calc(100dvh-680px)]',
]);

export const accordionItem = cva([
  'flex',
  'items-center',
  'pl-6',
  'gap-2',
  'first:mt-3',
]);

export const verticalDivider = cva([
  'w-[2px]',
  'h-12',
  'border-r-2',
  'border-brand-100',
]);

export const link = cva(
  [
    'flex',
    'items-center',
    'gap-x-3.5',
    'p-3.5',
    'w-full',
    'h-full',
    'rounded-md',
    'text-sm',
    'font-inter',
    'truncate',
  ],
  {
    variants: {
      active: {
        true: ['bg-brand-gradient', 'text-brand-50'],
        false: [
          'text-neutral-700',
          'hover:bg-brand-50',
          'hover:text-brand-400',
        ],
      },
      danger: {
        true: [
          'hover:bg-driver-centricity-50',
          'hover:text-driver-centricity-600',
        ],
      },
    },
    defaultVariants: {
      active: false,
    },
  },
);

export const icon = cva([''], {
  variants: {
    isActive: {
      true: ['text-white'],
      false: ['text-brand-pure'],
    },
  },
  defaultVariants: {
    isActive: false,
  },
});

export const itemText = cva(['group-data-[collapsed=true]:sr-only']);

export const itemTextTitle = cva([
  'group-data-[collapsed=true]:sr-only',
  'flex',
  'w-full',
  'items-center',
  'justify-between',
]);
