import { useFlags } from 'flagsmith/react';

type FeatureFlagProps = {
  flag: string;
  children: React.ReactNode;
};

export function FeatureFlag({ flag, children }: FeatureFlagProps) {
  const feature = useFlags([flag]);

  const { enabled } = feature[flag] || {};

  if (enabled) return children;

  return null;
}
