import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';
import { RootLayout } from '@/layouts/Root';

import { NewContractForm } from '../components/NewContractForm';
import { NewQuoteForm } from '../components/NewQuoteForm';

const Quotes = lazy(() => import('@/modules/quotes/pages/Quotes'));
const NewQuote = lazy(() => import('@/modules/quotes/pages/NewQuote'));
const QuoteSteps = lazy(() => import('@/modules/quotes/pages/QuoteSteps'));
const NewContract = lazy(() => import('@/modules/quotes/pages/NewContract'));
const ContractSteps = lazy(
  () => import('@/modules/quotes/pages/ContractSteps'),
);
const Cart = lazy(() => import('@/modules/quotes/pages/Cart'));
const StepsNewDealProduct = lazy(
  () => import('@/modules/quotes/pages/StepsNewDealProduct'),
);

export const QuotesRoutes: RouteObject[] = [
  {
    element: <RootLayout />,
    children: [
      {
        path: links.quotes.list,
        element: <Quotes />,
      },
      {
        path: links.quotes.cart(),
        element: <Cart />,
      },
      {
        path: links.quotes.newProduct,
        element: <StepsNewDealProduct />,
      },
      {
        element: <NewQuoteForm />,
        children: [
          {
            path: links.quotes.new,
            element: <NewQuote />,
          },
          {
            path: links.quotes.steps,
            element: <QuoteSteps />,
          },
        ],
      },
      {
        element: <NewContractForm />,
        children: [
          {
            path: links.quotes.newContract,
            element: <NewContract />,
          },
          {
            path: links.quotes.contractSteps,
            element: <ContractSteps />,
          },
        ],
      },
    ],
  },
];
