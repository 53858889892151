import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';
import { RootLayout } from '@/layouts/Root';

const Stores = lazy(() => import('@/modules/stores/pages/Stores'));

export const StoresRoutes: RouteObject[] = [
  {
    element: <RootLayout />,
    children: [
      {
        path: links.stores,
        element: <Stores />,
      },
    ],
  },
];
