import { z } from 'zod';

const nodeEnv = z.enum(['development', 'production']);

const envSchema = z.object({
  MODE: nodeEnv,
  VITE_API_URL: z.string().nonempty(),
  VITE_FLAGSMITH_ENV_ID: z.string().nonempty(),
  VITE_TERMS_OF_USE_URL: z.string().nonempty(),
  VITE_GOOGLE_RECAPTCHA_SITE_KEY: z.string().nonempty(),
});

export const ENV = envSchema.parse(import.meta.env);
