import { ToastContainer as ToastC, ToastContainerProps } from 'react-toastify';

export function ToastContainer(props: ToastContainerProps) {
  return (
    <ToastC
      position="top-right"
      autoClose={3000}
      closeButton={false}
      icon={false}
      closeOnClick={false}
      toastClassName={() => ''}
      hideProgressBar
      enableMultiContainer
      pauseOnHover
      {...props}
    />
  );
}
