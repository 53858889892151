import { useQuery } from '@tanstack/react-query';

import { httpClient } from '@/config/httpClient';

import { getUserInfo } from '../services/getUserInfo';

export function useGetUserInfo() {
  const bearerToken = httpClient.defaults.headers.Authorization;

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['getUserInfo', bearerToken],
    async () => getUserInfo(),
    {
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60 * 60, // 1 hour
      enabled: !!bearerToken,
    },
  );

  return {
    user: data?.data,
    isLoadingUser: isLoading && isFetching,
    refetch,
  };
}
